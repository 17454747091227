import before1 from "../components/img/before1.jpg";
import before2 from "../components/img/before2.jpg";
import before3 from "../components/img/before3.jpg";
import before4 from "../components/img/before4.jpg";
import before5 from "../components/img/before5.jpg";

import after1 from "../components/img/after1.jpg";
import after2 from "../components/img/after2.jpg";
import after3 from "../components/img/after3.jpg";
import after4 from "../components/img/after4.jpg";
import after5 from "../components/img/after5.jpg";

import male from "../components/img/male.svg";
import female from "../components/img/female.svg";

export const aboutArr = [
  "КРАТКО ОБО МНЕ",
  "МОЯ СПЕЦИАЛИЗАЦИЯ",
  "МЕТОДЫ ЛЕЧЕНИЯ",
  "КОМАНДНЫЙ ПОДХОД",
];

export const aboutArrDescs = [
  `    В настоящее время работаю в ФГБУ ЦКБ с поликлиникой Управления делами Президента РФ* и команде врачей спасения нижних конечностей при угрозе ампутации КИНК.РФ**`,
  `С 2005 года занимаюсь лечением пациентов с сахарным диабетом, диабетической нейропатией, диабетической стопой, различными ранами, подологическими проблемами (заболевания кожи и ногтей на стопах, вросший ноготь, вирусные бородавки, деформации стоп, мозоли, травмы) и осуществляю индивидуальное ортезирование.`,
  `В своей клинической практике я использую прежде всего международные стандарты лечения! 
    В международные стандарты входят современные сахароснижающие средства, обезболивающие, антибактериальные препараты, оптимальные повязки из современных материалов, разгрузочные приспособления, при этом много работаю руками (делаю сама хирургическую обработку и перевязки ран, ортониксию при вросшем ногте, изготовляю разгрузочные повязки, индивидуальные ортезы (стельки), силиконовые ортезы, кинезиомассаж, кинезиотейпирование и др.). 
    Такая тактика позволяет мне максимально эффективно и в короткие сроки уменьшить боль в ногах и/или заживить рану на стопе у моих пациентов и проводить профилактику поражений нижних конечностей.`,
  `Я работаю в команде врачей-единомышленников, и если пациенту будет необходимо, мы с гнойным, хирургом-ортопедом, сосудистым хирургом, кардиологом, ортопедом, неврологом, реабилитологом, кинезиологом, дерматологом и другими специалистами организуем комплексную медицинскую помощь на базе многопрофильного медицинского учреждения ФГБУ ЦКБ с поликлиникой Управления делами Президента РФ или в команде по спасению нижних конечностей при угрозе ампутации КИНК.РФ.`,
];

export const aboutSize = [
  "min(54px, 2.7vw)",
  "min(52px, 2.6vw)",
  "min(34px, 1.7vw)",
  "min(44px, 2.2vw)",
];

export const aboutGrad = [
  "linear-gradient( 345deg, transparent 0%, transparent 50%, #419fff 89%, #419fff 100% )",
  "linear-gradient( 355deg, transparent 0%, transparent 50%, #419fff 89%, #419fff 100% )",
  "linear-gradient( 365deg, transparent 0%, transparent 50%, #419fff 89%, #419fff 100% )",
  "linear-gradient( 375deg, transparent 0%, transparent 50%, #419fff 89%, #419fff 100% )",
];

export const before = [before1, before2, before3, before4, before5];
export const after = [after1, after2, after3, after4, after5];
export const baText = [
  `Синдром диабетической стопы нейроишемической формы. Критическая ишемия левой нижней конечности. Язвенный дефект пяточной области левой стопы.
`,
  `Синдром диабетической стопы нейропатической формы. Язвенный дефект левой пяточной области.
  `,
  `Синдром диабетической стопы нейропатической формы. Ампутация 1 пальца. Язвенный дефект подошвенной поверхности левой стопы.
  `,
  `Синдром диабетической стопы нейропатической формы. Послеоперационная рана тыльной поверхности стопы. `,
  `Васкулит. Язвенные дефекты тыльной поверхности обеих стоп.`,
];

export const about1 = `Я врач-эндокринолог, подолог (подиатр), кандидат медицинских наук. `;

export const about2 = `В настоящее время работаю в ФГБУ ЦКБ с поликлиникой Управления делами Президента РФ* и команде врачей спасения нижних конечностей при угрозе ампутации КИНК.РФ**`;

export const about3 = `С 2005 года занимаюсь лечением пациентов с сахарным диабетом, диабетической нейропатией, диабетической стопой, различными ранами, подологическими проблемами (заболевания кожи и ногтей на стопах, вросший ноготь, вирусные бородавки, деформации стоп, мозоли, травмы) и осуществляю индивидуальное ортезирование`;

export const whatDo = `    
    В своей клинической практике я использую прежде всего международные стандарты лечения! 
    В международные стандарты входят современные сахароснижающие средства, обезболивающие, антибактериальные препараты, оптимальные повязки из современных материалов, разгрузочные приспособления, при этом много работаю руками (делаю сама хирургическую обработку и перевязки ран, ортониксию при вросшем ногте, изготовляю разгрузочные повязки, индивидуальные ортезы (стельки), силиконовые ортезы, кинезиомассаж, кинезиотейпирование и др.). 
    Такая тактика позволяет мне максимально эффективно и в короткие сроки уменьшить боль в ногах и/или заживить рану на стопе у моих пациентов и проводить профилактику поражений нижних конечностей.`;

export const canDo1 = `     Это самое распространенное осложнение сахарного диабета (СД). 
Каждому пациенту с СД рекомендуется минимум 1 раз в год проводить обследование для выявления нейропатии. 
Проведу обследование, объясню как предотвратить это осложнение СД и назначу лечение при наличии боли или неприятных ощущений в ногах.
`;
export const canDo2 = `     Самое тяжелое осложнение сахарного диабета, которое проявляется язвами кожи, переломами костей стопы, инфекцией и нарушением кровоснабжения нижних конечностей.
    Провожу диагностику, лечение и профилактику диабетической стопы.
`;
export const canDo3 = `     Более 20 лет занимаюсь лечением ран и язв кожи различной этиологии (постоперационные, ожоговые, пролежни, венозные, атипичные, на фоне приема глюкокортикоидов и противоопухолевых препаратов и др.). 
    Применяю современные и инновационные методы лечения, в том числе клеточную терапию.
`;
export const canDo4 = `    Осуществляю диагностику и лечение различных патологий стопы  в рамках подологии (вросший ноготь, панариций, дистрофии ногтей, вирусные бородавки, деформации стоп, пяточная шпора, халюкс вальгус, плоскостопие, мозоли, травмы и др.).
     Изготавливаю индивидуальные ортезы (стельки) для стоп по системе FormThotics. В работе использую кинезиомассаж и кинезиотейпирование нижних конечностей.
`;

export const headerCanDo1 = `Диабетическая нейропатия
`;
export const headerCanDo2 = `Диабетическая стопа
`;
export const headerCanDo3 = `Острые и хронические раны
`;
export const headerCanDo4 = `Заболевания стопы
`;

export const education = [
  {
    year: "1997 - 2003",
    desc: "Лечебный факультет Московской медицинской академии им. И.М. Сеченова",
  },
  // {
  //   year: "2005",
  //   desc: "",
  // },
  {
    year: "2010",
    desc: "Диабетология, Сент — Эндрюсский Университет, Великобритания",
  },
  {
    year: "2014",
    desc: "Первая квалификационная категория по специальности «Эндокринология»",
  },
  {
    year: "2022",
    desc: `- Семинары «Подиатрия». Ассоциация «Лига подиатрии»

    - Семинары «Ортезирование стоп по системе Формтотикс», «Детская подиатрия, коррекция стоп и постуры у детей медицинской системой Формтотикс» Ассоциация «Лига подиатрии»

    - Семинар «Принципы коррекции ортезов Формтотикс: интерпретация функциональных тестов» Ассоциация «Лига подиатрии»

    - Семинар «Диагностика и дифференциальная диагностика анатомической разницы длины нижних конечностей» Ассоциация «Лига подиатрии»
    
    - Семинары «Кинезиомассаж», «Вегетативная нервная система» Учебный центр «Школа доктора Крутова»`,
  },
  // {
  //   year: "2022",
  //   desc: "",
  // },
  // {
  //   year: "2022",
  //   desc: "",
  // },
  // {
  //   year: "2022",
  //   desc: "",
  // },
  // {
  //   year: "2022",
  //   desc: "",
  // },
  {
    year: "2023",
    desc: "Доцент кафедры эндокринологии и диабетологии РНИМУ им. Н.И. Пирогова",
  },
];

export const exp1 = [
  { year: "с 2001 г.", title: "Медицинский стаж:" },
  { year: "с 2005 г.", title: "Врачебный стаж:" },
  { year: "с 2007 по 2022 г.", title: "Педагогический стаж:" },
];

export const exp2 = [
  {
    year: "2005 — 2022",
    title:
      "Врач-эндокринолог кабинета «Диабетическая стопа» Первого МГМУ им. И.М. Сеченова",
  },
  {
    year: "2007 — 2022",
    title: "Ассистент кафедры эндокринологии №1 Первого МГМУ им. И.М. Сеченова",
  },
  {
    year: "2015 — 2020",
    title:
      "Врач-эндокринолог Международного института психосоматического здоровья",
  },
  {
    year: "2020 - 2023",
    title: "Врач-эндокринолог итальянского медицинского центра холдинга GVM",
  },
  {
    year: "2022",
    title: `Врач-эндокринолог эндокринологического отделения с кабинетами "Диабетической стопы" и "Нарушений дыхания во сне" ФГБУ "ЦКБ с поликлиникой" Управления делами Президента РФ`,
  },
  {
    year: "2023",
    title: `Начальник Центра подологии ФГБУ "ЦКБ с поликлиникой" Управления делами Президента РФ`,
  },
];

export const review1 = {
  name: "Наталья",
  text: " Огромная благодарность доктору данного кабинета!!!!",
  img: female,
};
export const review2 = {
  img: male,
  name: "Владимир Григоров",
  text: ` Пожалуй единственное место в Москве с компетентными специалистами. Благодаря им мне удалось сохранить ногу. Очень реомендую`,
};
export const review3 = {
  img: female,
  name: "Вероника Тимакова",
  text: ` Если вы хоть один ращ пользовались услугами врачей которые там работабт то никуда больше обращаться не стоит!
  Молодцы!!!
  
  Помогли обойтись без операции!!! Грамотное лечение и отношение к клиенту!!!`,
};
export const review4 = {
  img: female,
  name: "dr__muradova",
  text: ` Огромное спасибо @doctor_maximova, и ее команде, что дали возможность поучиться и увидеть, как работают настоящие провессионалы.
  
  Материал изложен настолько четко и ясно, что я каждую лекцию переслушиваю уже по несколько раз`,
};
export const review5 = {
  img: male,
  name: "doctor_antonivanov",
  text: ` Хочу выразить свою благодарность Максимовой Надежде Викторовне и ее команде, что продемонстрировали нам ваш высокий уровень знаний по специальности!
  Добросовестность, ответственность и вашу полную вовлеченность!
  Также выражаю мою признательность и благодарность за ваш труд и огромный вклад в наше обучение и развитие кафедры молодых кадров`,
};

export const title = [
  { title: "Прием (осмотр, консультация) первичный", price: "4500" },
  { title: "Прием (осмотр, консультация) повторный", price: "4000" },
  { title: "Перевязка раны", price: "1000 – 2500" },
  { title: "Хирургическое удаление вросшего ногтя", price: "5000" },
  { title: "Медицинский аппаратный педикюр 1 ногтя", price: "1000" },
  {
    title: "Медицинский аппаратный педикюр ногтей и кожи одной стопы (30 мин)",
    price: "2000",
  },
  {
    title:
      "Медицинский аппаратный педикюр ногтей и кожи обеих стоп (60 мин) 1 категории сложности",
    price: "3000",
  },
  {
    title:
      "Медицинский аппаратный педикюр ногтей и кожи обеих стоп (90 мин) 2 категории сложности",
    price: "5000",
  },
  {
    title:
      "Изготовление индивидуального силиконового ортеза (с материалом) на 1 палец",
    price: "5000",
  },
  { title: "Установка титановой нити (1 ноготь)", price: "5500" },
  { title: "Деструкция 1 бородавки (химический метод)", price: "1000" },
  { title: "Наложение циркулярной разгрузочной повязки", price: "5300" },
  {
    title: "Изготовление индивидуальных ортезов Formthotics (с полуфабрикатом)",
    price: "9000",
  },
  {
    title:
      "Чрескожный мониторинг парциального давления кислорода (2-х канальное мониторирование)",
    price: "4000",
  },
  {
    title:
      "Определение процентного соотношения воды, мышечной и жировой ткани с помощью биоимпедансметра",
    price: "1000",
  },
  { title: "Кинезиотейпирование", price: "700 – 2500" },
  { title: "Кинезиомассаж", price: "5000" },
];
export const price = [
  { price: "4500" },
  { price: "4000" },
  { price: "1000 – 2500" },
  { price: "5000" },
  { price: "1000" },
  { price: "2000" },
  { price: "3000" },
  { price: "5000" },
  { price: "5000" },
  { price: "5500" },
  { price: "1000" },
  { price: "5300" },
  { price: "9000" },
  { price: "4000" },
  { price: "1000" },
  { price: "700 – 2500" },
  { price: "5000" },
];

import { EducationGrand } from "../global/components/style/Education/style";
import {
  Block1Back,
  FooterRow,
  FooterText,
  MainHeaderCanDo,
  YouTube,
} from "../global/components/style/NewStyle";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import img from "../global/components/img/placemark.png";
import {
  FooterIcon,
  FooterNew,
  FooterTop,
  FooterTopBox,
  FooterTopBoxCopy,
  FooterTopIcon,
  FooterTopTitle,
  MapBox,
} from "../global/components/style/Reviews/style";
import { useEffect, useRef, useState } from "react";
import phone from "../global/components/img/phone.svg";
import mail from "../global/components/img/mail.svg";
import loc from "../global/components/img/loc.svg";
import { isMobile } from "react-device-detect";
const youtubelink = "https://youtube.com/@doctor_maximova?si=NXHsW5ZpKj2RWNNn";

const Mapp = () => {
  const defaultState = {
    center: [55.751213, 37.382263],
    zoom: 17,
    controls: ["zoomControl"],
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const ref = useRef();
  const [w, setW] = useState(48);

  useEffect(() => {
    setW(Math.floor(ref.current.offsetWidth / 15));
    window.addEventListener("resize", (e) => {
      setW(Math.floor(ref.current.offsetWidth / 15));
    });

    return window.removeEventListener("resize", (e) => {
      setW(Math.floor(ref.current.offsetWidth / 15));
    });
  }, []);

  return (
    <Block1Back style={{ background: "#2564a3" }}>
      <EducationGrand>
        {isMobile ? (
          <MainHeaderCanDo
            style={{ color: "white", fontSize: "min(70px, 3.5vw)" }}
          >{`КОНТАКТЫ`}</MainHeaderCanDo>
        ) : (
          <MainHeaderCanDo
            style={{ color: "white" }}
          >{`КОНТАКТЫ`}</MainHeaderCanDo>
        )}
        <MapBox
          style={{
            width: isMobile ? "90%" : "2000px",
            maxWidth: isMobile ? "" : "70%",
          }}
          ref={ref}
        >
          <YMaps>
            <Map
              modules={[
                "geoObject.addon.balloon",
                "geoObject.addon.hint",
                "control.ZoomControl",
              ]}
              width={"100%"}
              height={"100%"}
              state={defaultState}
            >
              <Placemark
                geometry={[55.751213, 37.382263]}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: img,
                  iconImageSize: [w, w],
                  iconImageOffset: [-(w / 2), -w],
                }}
              />
            </Map>
          </YMaps>
        </MapBox>
        <FooterNew>
          {isMobile ? (
            <FooterTop>
              <FooterTopBoxCopy>
                <FooterTopBox>
                  <FooterIcon>
                    <FooterTopIcon url={phone}></FooterTopIcon>
                  </FooterIcon>
                  <FooterTopTitle>{"+7 (926) 697-85-43"}</FooterTopTitle>
                </FooterTopBox>

                <FooterTopBox>
                  <FooterIcon>
                    <FooterTopIcon
                      url={mail}
                      style={{ backgroundSize: "80%" }}
                    ></FooterTopIcon>
                  </FooterIcon>
                  <FooterTopTitle>{"stopa1med@mail.ru"}</FooterTopTitle>
                </FooterTopBox>

                <YouTube
                  style={{ width: "min(120px, 6vw)" }}
                  onClick={() => openInNewTab(youtubelink)}
                ></YouTube>
              </FooterTopBoxCopy>

              <FooterTopBox>
                <FooterIcon>
                  <FooterTopIcon url={loc}></FooterTopIcon>
                </FooterIcon>
                <FooterTopTitle>
                  {
                    "г. Москва, ул. Маршала Тимошенко д. 15 стр. 5, 2-ой этаж каб. 238-240"
                  }
                </FooterTopTitle>
              </FooterTopBox>
            </FooterTop>
          ) : (
            <FooterTop style={{ width: "2000px", maxWidth: "80%" }}>
              <FooterTopBoxCopy>
                <FooterTopBox>
                  <FooterIcon>
                    <FooterTopIcon
                      url={phone}
                      style={{
                        width: "min(60px, 3vw)",
                        height: "min(60px, 3vw)",
                      }}
                    ></FooterTopIcon>
                  </FooterIcon>
                  <FooterTopTitle style={{ fontSize: "min(26px, 1.3vw)" }}>
                    {"+7 (926) 697-85-43"}
                  </FooterTopTitle>
                </FooterTopBox>

                <FooterTopBox>
                  <FooterIcon>
                    <FooterTopIcon
                      url={mail}
                      style={{
                        backgroundSize: "80%",
                        width: "min(60px, 3vw)",
                        height: "min(60px, 3vw)",
                      }}
                    ></FooterTopIcon>
                  </FooterIcon>
                  <FooterTopTitle style={{ fontSize: "min(26px, 1.3vw)" }}>
                    {"stopa1med@mail.ru"}
                  </FooterTopTitle>
                </FooterTopBox>

                <YouTube
                  style={{ width: "min(100px, 5vw)" }}
                  onClick={() => openInNewTab(youtubelink)}
                ></YouTube>
              </FooterTopBoxCopy>

              <FooterTopBox>
                <FooterIcon>
                  <FooterTopIcon
                    url={loc}
                    style={{
                      width: "min(60px, 3vw)",
                      height: "min(60px, 3vw)",
                    }}
                  ></FooterTopIcon>
                </FooterIcon>
                <FooterTopTitle style={{ fontSize: "min(26px, 1.3vw)" }}>
                  {
                    "г. Москва, ул. Маршала Тимошенко д. 15 стр. 5, 2-ой этаж каб. 238-240"
                  }
                </FooterTopTitle>
              </FooterTopBox>
            </FooterTop>
          )}

          {/* <Column style={{ width: "70%", position: "relative" }}>
            <PhoneBox>
              <PhoneNumberFlex>
                <PhoneIcon></PhoneIcon>
                <PhoneNumber>{"+7 (926) 697-85-43"}</PhoneNumber>
              </PhoneNumberFlex>
              <Enroll>{"записаться"}</Enroll>
            </PhoneBox>
            <TWBox>
              <Telegram></Telegram>
              <Whatsapp></Whatsapp>
            </TWBox>
          </Column> */}
        </FooterNew>

        {isMobile ? (
          <FooterRow>
            <FooterText>2023</FooterText>
            <FooterText>•</FooterText>
            <FooterText>docmaximova</FooterText>
            <FooterText>•</FooterText>
            <FooterText style={{ border: "none" }}>Москва</FooterText>
          </FooterRow>
        ) : (
          <FooterRow style={{ height: "min(40px, 2vw)" }}>
            <FooterText style={{ fontSize: "min(16px, 0.8vw)" }}>
              2023
            </FooterText>
            <FooterText style={{ fontSize: "min(16px, 0.8vw)" }}>•</FooterText>
            <FooterText style={{ fontSize: "min(16px, 0.8vw)" }}>
              docmaximova
            </FooterText>
            <FooterText style={{ fontSize: "min(16px, 0.8vw)" }}>•</FooterText>
            <FooterText
              style={{ border: "none", fontSize: "min(16px, 0.8vw)" }}
            >
              Москва
            </FooterText>
          </FooterRow>
        )}
      </EducationGrand>
    </Block1Back>
  );
};

export default Mapp;

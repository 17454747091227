import { education } from "../global/components/constants";
import {
  EducationGrand,
  EducationImage,
  EducationLeft,
  EducationRight,
  EducationRow,
  EducationYear,
} from "../global/components/style/Education/style";
import {
  Block1Back,
  MainHeaderCanDo,
} from "../global/components/style/NewStyle";
import { isMobile } from "react-device-detect";

const Education = () => {
  return (
    <Block1Back style={{ background: "transparent" }}>
      <EducationGrand style={{ maxWidth: isMobile ? "100%" : "70%" }}>
        {isMobile ? (
          <MainHeaderCanDo style={{ fontSize: "min(70px, 3.5vw)" }}>
            образование
          </MainHeaderCanDo>
        ) : (
          <MainHeaderCanDo>образование</MainHeaderCanDo>
        )}
        {education.map((el, i) => {
          return !isMobile ? (
            <EducationRow style={{ whiteSpace: "pre-line" }}>
              <EducationLeft style={{ top: "max(-34px, -1.7vw)" }}>
                <EducationImage></EducationImage>
                <EducationYear style={{ fontSize: "min(30px, 1.5vw)" }}>
                  {el.year}
                </EducationYear>
              </EducationLeft>
              <EducationRight
                style={{
                  fontSize: "min(24px, 1.2vw)",
                  paddingBottom: "min(32px, 1.6vw)",
                }}
              >
                {el.desc}
              </EducationRight>
            </EducationRow>
          ) : (
            <EducationRow style={{ whiteSpace: "pre-line", marginTop: "7%" }}>
              <EducationLeft style={{ width: "40%", borderRadius: "100px" }}>
                <EducationImage
                  style={{ width: "23%", paddingTop: "21%" }}
                ></EducationImage>
                <EducationYear
                  style={{
                    paddingLeft: "min(30px, 1.5vw)",
                    fontSize: "min(70px, 3.5vw)",
                  }}
                >
                  {el.year}
                </EducationYear>
              </EducationLeft>
              <EducationRight
                style={{
                  marginTop: "min(36px, 1.8vw)",
                  paddingTop: "min(100px, 5vw)",
                  fontSize: "min(50px, 2.5vw)",
                }}
              >
                {el.desc}
              </EducationRight>
            </EducationRow>
          );
        })}
      </EducationGrand>
    </Block1Back>
  );
};

export default Education;

import { styled } from "styled-components";
import logo from "../../img/logo.jpg";

export const EducationGrand = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2000px;
  max-width: 100%;
`;

export const EducationRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 5%;
  &:last-child {
    margin-bottom: 4%;
  }
`;

export const EducationLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  left: 0;
  top: max(-60px, -3vw);
  background-color: #2a78c7;
  padding: min(10px, 0.5vw);
  border-radius: max(40px, 2vw);
  position: absolute;
`;

export const EducationYear = styled.div`
  width: 75%;
  color: white;
  /* text-align: right; */
  padding-left: min(16px, 0.8vw);
  font-weight: 600;
  font-size: min(50px, 2.5vw);
  text-shadow: 0 1px 1px black;
`;

export const EducationImage = styled.div`
  width: 22%;
  padding-top: 19%;
  background: url(${logo});
  background-size: cover;
  background-position: max(-10px, -0.5vw) center;
  border-radius: 100%;
  border: 2px solid #818f9e;
`;

export const EducationRight = styled.div`
  width: 96%;
  /* font-weight: 600; */
  padding: min(48px, 4vw);
  padding-top: min(74px, 3.7vw);
  background-color: rgb(198 234 255);
  border-radius: min(30px, 1.5vw);
  font-size: min(26px, 1.3vw);
`;

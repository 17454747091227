import { useEffect, useRef, useState } from "react";
import {
  ModalBack,
  ModalButton,
  ModalDesc,
  ModalGrand,
  NameArea,
  PhoneArea,
} from "../global/components/style/Modal/style";
import { send } from "emailjs-com";
import { isMobile } from "react-device-detect";

const Modal = ({ setShowModal }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [focused, setFocused] = useState(false);

  const [blow, setBlow] = useState(true);

  const nameRef = useRef();
  const phoneRef = useRef();

  const close = () => {
    setBlow(false);
    setTimeout(() => {
      setShowModal(false);
    }, 330);
  };

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  return (
    <>
      <ModalGrand blow={blow} style={{ width: isMobile ? "90%" : "900px" }}>
        {isMobile ? (
          <ModalDesc style={{ fontSize: "min(50px, 2.5vw)" }}>
            Пожалуйста, заполните обязательные поля формы и отправьте заявку.
          </ModalDesc>
        ) : (
          <ModalDesc>
            Пожалуйста, заполните обязательные поля формы и отправьте заявку.
          </ModalDesc>
        )}
        {isMobile ? (
          <NameArea
            ref={nameRef}
            placeholder="Ваше имя"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              fontSize: "min(50px, 2.5vw)",
              paddingTop: "min(60px, 3vw)",
            }}
          ></NameArea>
        ) : (
          <NameArea
            ref={nameRef}
            placeholder="Ваше имя"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></NameArea>
        )}
        {isMobile ? (
          <PhoneArea
            ref={phoneRef}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder="Телефон"
            // value={phone === "" && !focused ? "" : phone}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              // if (Number(e.target) + 1) {
              //   if (phone.length < 18) {
              //     if (phone.length === 3) {
              //       setPhone((prev) => `${prev}(${e.key}`);
              //     } else if (phone.length === 6) {
              //       setPhone((prev) => `${prev}${e.key}) `);
              //     } else if (phone.length === 11 || phone.length === 14) {
              //       setPhone((prev) => `${prev}${e.key}-`);
              //     } else {
              //       setPhone((prev) => `${prev}${e.key}`);
              //     }
              //   }
              // } else if (e.key === "Backspace") {
              //   if (phone.length > 3) {
              //     if (phone.length === 5) {
              //       setPhone("+7 ");
              //     } else if (phone.length === 9) {
              //       setPhone((prev) => prev.slice(0, prev.length - 3));
              //     } else if (phone.length === 13 || phone.length === 16) {
              //       setPhone((prev) => prev.slice(0, prev.length - 2));
              //     } else {
              //       setPhone((prev) => prev.slice(0, prev.length - 1));
              //     }
              //   }
              // }
            }}
            // onKeyUp={(e) => {
            //   if (Number(e.key) + 1) {
            //     if (phone.length < 18) {
            //       if (phone.length === 3) {
            //         setPhone((prev) => `${prev}(${e.key}`);
            //       } else if (phone.length === 6) {
            //         setPhone((prev) => `${prev}${e.key}) `);
            //       } else if (phone.length === 11 || phone.length === 14) {
            //         setPhone((prev) => `${prev}${e.key}-`);
            //       } else {
            //         setPhone((prev) => `${prev}${e.key}`);
            //       }
            //     }
            //   } else if (e.key === "Backspace") {
            //     if (phone.length > 3) {
            //       if (phone.length === 5) {
            //         setPhone("+7 ");
            //       } else if (phone.length === 9) {
            //         setPhone((prev) => prev.slice(0, prev.length - 3));
            //       } else if (phone.length === 13 || phone.length === 16) {
            //         setPhone((prev) => prev.slice(0, prev.length - 2));
            //       } else {
            //         setPhone((prev) => prev.slice(0, prev.length - 1));
            //       }
            //     }
            //   }
            // }}
            style={{
              fontSize: "min(50px, 2.5vw)",
              paddingTop: "min(60px, 3vw)",
            }}
          ></PhoneArea>
        ) : (
          <PhoneArea
            ref={phoneRef}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder="Телефон"
            // value={phone === "" && !focused ? "" : phone}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            // onKeyUp={(e) => {
            //   if (Number(e.key) + 1) {
            //     if (phone.length < 18) {
            //       if (phone.length === 3) {
            //         setPhone((prev) => `${prev}(${e.key}`);
            //       } else if (phone.length === 6) {
            //         setPhone((prev) => `${prev}${e.key}) `);
            //       } else if (phone.length === 11 || phone.length === 14) {
            //         setPhone((prev) => `${prev}${e.key}-`);
            //       } else {
            //         setPhone((prev) => `${prev}${e.key}`);
            //       }
            //     }
            //   } else if (e.key === "Backspace") {
            //     if (phone.length > 3) {
            //       if (phone.length === 5) {
            //         setPhone("+7 ");
            //       } else if (phone.length === 9) {
            //         setPhone((prev) => prev.slice(0, prev.length - 3));
            //       } else if (phone.length === 13 || phone.length === 16) {
            //         setPhone((prev) => prev.slice(0, prev.length - 2));
            //       } else {
            //         setPhone((prev) => prev.slice(0, prev.length - 1));
            //       }
            //     }
            //   }
            // }}
          ></PhoneArea>
        )}
        {isMobile ? (
          <ModalButton
            style={{ fontSize: "min(50px, 2.5vw" }}
            enabled={phone.trim() && name.trim()}
            onClick={() => {
              if (phone.trim() && name.trim()) {
                close();
                send(
                  "service_te5rt9b",
                  "template_2rl0nmc",
                  { from_name: name, message: phone },
                  "1Ph_hTF2kTGH49CiU"
                )
                  .then((response) => {
                    console.log("SUCCESS!", response.status, response.text);
                  })
                  .catch((err) => {
                    console.log("FAILED...", err);
                  });
              }
            }}
          >
            ЗАПИСАТЬСЯ НА ПРИЕМ
          </ModalButton>
        ) : (
          <ModalButton
            enabled={phone.trim() && name.trim()}
            onClick={() => {
              if (phone.trim() && name.trim()) {
                close();
                send(
                  "service_te5rt9b",
                  "template_2rl0nmc",
                  { from_name: name, message: phone },
                  "1Ph_hTF2kTGH49CiU"
                )
                  .then((response) => {
                    console.log("SUCCESS!", response.status, response.text);
                  })
                  .catch((err) => {
                    console.log("FAILED...", err);
                  });
              }
            }}
          >
            ЗАПИСАТЬСЯ НА ПРИЕМ
          </ModalButton>
        )}
      </ModalGrand>
      <ModalBack blow={blow} onClick={() => close()}></ModalBack>
    </>
  );
};

export default Modal;

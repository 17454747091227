import { styled } from "styled-components";
import star from "../../img/star.svg";

export const ReviewRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const ReviewBlockBig = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* width: 48%; */
  /* aspect-ratio: 3 / 2; */
  background-color: #ffffff;
  /* background-color: black; */
  border-radius: min(30px, 1.5vw);
  box-shadow: inset 0 -1px 1px #515151;
  /* border-right: 1px solid #9ec4ea; */
  overflow: hidden;
  &:last-child {
    border: none;
  }
  /* position: absolute; */
  width: 70%;
  &:not(:first-child) {
    margin-top: 3%;
  }
`;

export const ReviewBlockSmall = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* width: 31.5%; */
  /* aspect-ratio: 5 / 4; */
  background-color: #ffffff;
  /* border-radius: min(30px, 1.5vw); */
  margin-top: 1%;
  overflow: hidden;
  /* border-right: 1px solid #9ec4ea; */
  &:last-child {
    border: none;
  }
`;

export const ReviewTopBigFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: min(40px, 2vw);
  align-items: center;
  width: 100%;
  /* aspect-ratio: 10 / 1; */
  flex-shrink: 0;
  padding-top: min(20px, 1vw);
  padding-bottom: min(20px, 1vw);
`;

export const ReviewIcon = styled.div`
  width: min(80px, 4vw);
  height: min(80px, 4vw);
  /* aspect-ratio: 1 / 1; */
  background: url("${(props) => props.url}");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  background-color: #ffffff;
`;

export const ReviewName = styled.div`
  padding-left: min(24px, 1.2vw);
  font-size: min(30px, 1.5vw);
  font-weight: 600;
`;

export const ReviewTopSmallFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: min(20px, 1vw);
  align-items: center;
  width: 100%;
  /* aspect-ratio: 9 / 1; */
  flex-shrink: 0;
  padding-top: min(16px, 0.8vw);
  padding-bottom: min(16px, 0.8vw);
`;

export const ReviewStars = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  overflow: hidden;
  /* padding-left: min(20px, 1vw); */
  flex-shrink: 0;
  /* padding-bottom: min(30px, 1.5vw); */
`;

export const ReviewStar = styled.div`
  width: min(30px, 1.5vw);
  height: min(30px, 1.5vw);
  /* aspect-ratio: 1 / 1; */
  background: url(${star});
  background-position: center;
  background-size: 85%;
  background-repeat: no-repeat;
  filter: invert(37%) sepia(71%) saturate(975%) hue-rotate(180deg)
    brightness(93%) contrast(85%);
`;

export const ReviewText = styled.div`
  font-size: min(22px, 1.1vw);
  white-space: pre-wrap;
  padding: min(36px, 1.8vw);
  padding-top: min(10px, 0.5vw);
  /* font-weight: 600; */
  text-align: center;
  font-style: italic;
`;

export const MapBox = styled.div`
  position: relative;
  width: 90%;
  height: min(800px, 40vw);
  filter: grayscale(0.4);
  border-radius: min(30px, 1.5vw);
  box-shadow: 0 1px 1px #515151;
  overflow: hidden;
  transition: filter 0.3s;
  &:hover {
    filter: grayscale(0.1);
  }
  margin-bottom: min(50px, 2.5vw);
  /* aspect-ratio: 2 / 1; */
`;

export const FooterNew = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* gap: min(6px, 0.3vw); */
`;

export const FooterTopBoxCopy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: min(6px, 0.3vw); */
`;

export const FooterTopBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: min(12px, 0.6vw) min(24px, 1.2vw);
  &:hover {
    filter: brightness(1.1);
  }
`;

export const FooterIcon = styled.div`
  background-color: #3890e8;
  padding: min(12px, 0.6vw);
  border-radius: min(20px, 1vw);
`;

export const FooterTopIcon = styled.div`
  width: min(80px, 4vw);
  height: min(80px, 4vw);
  background: url(${(props) => props.url});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(100%) sepia(100%) saturate(14%) hue-rotate(212deg)
    brightness(104%) contrast(104%);
`;

export const FooterTopTitle = styled.div`
  font-size: min(40px, 2vw);
  font-weight: 600;
  padding: min(10px, 0.5vw) min(30px, 1.5vw);
  background-color: #f0f7ff;
  border-radius: 0 min(20px, 1vw) min(20px, 1vw) 0;
`;

import { styled } from "styled-components";

export const AboutGrandBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: rgba(255, 255, 255, 0.85); */
`;

export const AboutGrand = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2000px;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 1);
`;

export const AboutTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const AboutTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: min(80px, 4vw);
  font-weight: 600;
  font-size: ${(props) =>
    props.selected ? "min(28px, 1.4vw)" : "min(24px, 1.2vw)"};
  width: 25%;
  background-color: ${(props) => (props.selected ? "#419fff" : "#eaf4ff")};
  color: ${(props) => (props.selected ? "white" : "#515151")};
  /* text-decoration: ${(props) => (props.selected ? "underline" : "none")};
  text-underline-offset: 20%; */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${(props) => (props.selected ? "" : "#cde6ff")};
    color: ${(props) => (props.selected ? "" : "black")};
    /* font-size: ${(props) => (props.selected ? "" : "min(28px, 1.4vw)")}; */
  }
`;

export const AboutBottom = styled.div`
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  /* aspect-ratio: 4 / 1; */
  padding: min(60px, 3vw);
  text-align: center;
  color: #000000;
  /* font-weight: 700; */
  font-size: min(28px, 1.4vw);
  /* background: ${(props) => props.gradient}; */
`;

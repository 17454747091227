import { styled } from "styled-components";

export const TopExp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin-bottom: 2%;
  /* background-color: #afd7ff; */
`;

export const TopExpFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: min(20px, 1vw) min(40px, 2vw);
  border-right: 1px solid #9ec4ea;
  &:last-child {
    border-right: none;
  }
`;

export const TopExpTitle = styled.div`
  font-size: min(25px, 1.25vw);
  font-weight: 600;
  padding-left: min(35px, 1.75vw);
`;

export const TopExpYear = styled.div`
  font-size: min(35px, 1.75vw);
  color: #2a78c7;
  font-weight: 600;
  padding-left: min(20px, 1vw);
`;

export const ExpRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  border-bottom: 1px solid #9ec4ea;
  &:last-child {
    border-bottom: none;
  }
  transition: all 0.2s;
  &:hover {
    background-color: #e9f4ff;
  }
  /* background-color: #c9e4ff; */
  /* margin-top: 1%; */
`;

export const ExpYear = styled.div`
  padding: min(20px, 1vw);
  font-size: min(50px, 2.5vw);
  color: #2a78c7;
  font-weight: 600;
  flex-shrink: 0;
  width: 21%;
  text-align: right;
`;

export const ExpTitle = styled.div`
  font-size: min(30px, 1.5vw);
  /* font-weight: 600; */
  padding: min(15px, 0.75vw);
  padding-left: 0;
  width: 79%;
`;

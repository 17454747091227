import { styled } from "styled-components";
import back1 from "../../img/bw1.jpg";
import back2 from "../../img/bw2.jpg";
import back3 from "../../img/bw3.jpg";

export const BackgroundOne = styled.div`
  width: 100%;
  padding-top: 50%;
  /* aspect-ratio: 4 / 2; */
  background: url(${back3});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  filter: blur(2px);
  opacity: 0.5;
  z-index: -1;
  flex-shrink: 0;
`;

export const BackgroundTwo = styled.div`
  width: 100%;
  aspect-ratio: 4 / 2;
  background: url(${back2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  filter: blur(2px);
  opacity: 0.5;
  z-index: -1;
  flex-shrink: 0;
`;

export const BackgroundThree = styled.div`
  width: 100%;
  aspect-ratio: 4 / 2;
  background: url(${back1});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  filter: blur(2px);
  opacity: 0.5;
  z-index: -1;
  flex-shrink: 0;
`;

export const BackgroundFlex = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: -1;
`;
